import { FC, memo } from 'react';
import { useProductCount } from './ProductCountContext';
import useTableStyles from './useTableStyles';

type DatoProduct = {
  id: string;
  name: string;
};

type Props = {
  products: DatoProduct[];
  onSelect: (index: number, productId: string) => void;
  selectedIds: string[];
};

const ProductSelector: FC<Props> = ({ products, onSelect, selectedIds }) => {
  const productCount = useProductCount();
  const { gridClass, lastItemClass } = useTableStyles(productCount);
  const numberOfSelects = Math.max(2, Math.min(3, productCount));

  return (
    <div className={gridClass}>
      {Array.from({ length: numberOfSelects }, (_, index) => (
        <select
          key={index}
          value={selectedIds[index] || ''}
          onChange={e => onSelect(index, e.target.value)}
          className={`block w-full py-3 mb-4 overflow-hidden text-sm md:text-md font-bold placeholder-gray-400 border-gray-300 rounded-md  focus:outline-none focus:ring-nzxt-volt-400 focus:border-nzxt-volt-400 dark:bg-nzxt-light-grey-800 dark:border-nzxt-dark-grey-300  lg:text-lg dark:text-white overflow-ellipsis whitespace-nowrap text-nzxt-light-grey-800 ${lastItemClass}`}
        >
          {products.map(product => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>
      ))}
    </div>
  );
};

export default memo(ProductSelector);
