import { memo, FC } from 'react';
import useGridClass from './useTableStyles';
import { useProductCount } from './ProductCountContext';

type Props = {
  specs: Array<{ label: string; value: Array<string> }>;
};

const hasPipeCharacter = (str: string): boolean => str.includes('|');

const DataSection: FC<Props> = ({ specs }) => {
  const productCount = useProductCount();
  const { gridClass, lastItemClass } = useGridClass(productCount);

  if (!specs || specs.length === 0) {
    return null;
  }

  return (
    <>
      {specs.map(productSpecs => (
        <div key={productSpecs.label} className={gridClass}>
          {productSpecs.value.map((value, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${value}-${index}`}
              className={`py-2 pt-4 ${lastItemClass}`}
            >
              {hasPipeCharacter(value) ? (
                <>
                  {value.split('|').map((item, i) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item}-${i}`}
                      className="text-base font-semibold lg:text-lg text-nzxt-light-grey-800"
                    >
                      {item.trim()}
                    </div>
                  ))}
                </>
              ) : (
                <h5 className="text-base font-semibold lg:text-lg text-nzxt-light-grey-800">
                  {value}
                </h5>
              )}

              <p className="text-sm text-nzxt-dark-grey-300">
                {productSpecs.label}
              </p>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default memo(DataSection);
