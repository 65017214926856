import { memo, FC } from 'react';
import Image from '@components/Image';
import type { DatoProductImage } from '@framework/api/types/product';
import ContentWrapper from '@components/ContentWrapper';
import * as styles from './styles';

export type Props = {
  heading: string;
  subheading?: string;
  image?: DatoProductImage;
};

const Hero: FC<Props> = ({ heading, image, subheading }) => (
  <ContentWrapper
    data-test-id="hero-simple-content"
    className={styles.centeredItems}
  >
    <h1 className={styles.heading} data-test-id="hero-simple-heading">
      {heading}
    </h1>
    <p className={styles.subheading} data-test-id="hero-simple-subheading">
      {subheading}
    </p>
    {image && (
      <Image
        data={image.responsiveImage}
        width={image.responsiveImage.width}
        height={image.responsiveImage.height}
        loading="eager"
        dataTestID="hero-simple-image"
      />
    )}
  </ContentWrapper>
);

export default memo(Hero);
