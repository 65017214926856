/* eslint-disable camelcase */
import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import usePrice from '@framework/use-price';

import * as styles from './styles';

type Props = {
  priceBase: number;
  priceDiscount?: number;
  currencyCode: string;
};

const CollectionPrice: FC<Props> = ({
  priceBase,
  priceDiscount,
  currencyCode,
}) => {
  const { price } = usePrice({
    amount: priceBase ?? 0,
    currencyCode,
  });

  const { price: discoPrice } = usePrice({
    amount: priceDiscount ?? 0,
    currencyCode,
  });

  if (!priceBase) {
    return null;
  }

  const FROM = t('category_from');

  return (
    <span className={styles.priceContainer}>
      <span className="text-base mb-5" suppressHydrationWarning>
        {priceDiscount && priceDiscount < priceBase ? (
          <>
            {FROM}{' '}
            <span
              className="line-through text-nzxt-light-grey-600"
              suppressHydrationWarning
            >
              {price}
            </span>
            <span className="text-base mb-5 ml-2" suppressHydrationWarning>
              {discoPrice}
            </span>
          </>
        ) : (
          <span className="text-base mb-5" suppressHydrationWarning>
            {`${FROM} ${price}`}
          </span>
        )}
      </span>
    </span>
  );
};

export default memo(CollectionPrice);
