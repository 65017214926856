/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { memo, useMemo, FC } from 'react';
import { SubscriptionProductUpgradeOption } from 'fragile/types';
import cn from 'clsx';
import slugify from '@utils/slugify';
import { useTranslation as t } from '@utils/hooks';
import extraStyles from './extraStyles.module.css';
import * as styles from './buttons.styles';

type Props = {
  setUpgradeOption: (option: SubscriptionProductUpgradeOption) => void;
  selectedUpgradeOption: SubscriptionProductUpgradeOption;
  upgradeOptions: SubscriptionProductUpgradeOption[];
  isLarge?: boolean;
  productName?: string;
  asLink?: boolean;
  slug?: string;
  extraParam?: string;
  inventoryData?: {
    showInventory: boolean;
    stock?: number;
  };
  isSmall?: boolean;
};

const ColorSelectItems: FC<Props> = ({
  setUpgradeOption,
  selectedUpgradeOption,
  upgradeOptions,
  productName,
  slug,
  isSmall = false,
}) => {
  const CHOOSE_LABEL = t('choose_label');
  const handleSelect = (index: number): void => {
    setUpgradeOption(upgradeOptions[index]);
  };

  const smallSizeSelect = '32px';
  const defaultSizeSelect = '36px';
  const smallRadiusSize = '26px';
  const defaultRadiusSize = '30px';
  const smallPos = '-13px';
  const defaultPos = '-15px';
  const selectSize = isSmall ? smallSizeSelect : defaultSizeSelect;
  const radius = isSmall ? smallRadiusSize : defaultRadiusSize;
  const position = isSmall ? smallPos : defaultPos;

  const oneColor = {
    borderRadius: '50%',
    left: position,
  };

  const composedClassName = useMemo(
    () =>
      cn(extraStyles.swatch, {
        [extraStyles.swatchDefault]: !isSmall,
        [extraStyles.swatchSmall]: isSmall,
      }),
    [isSmall]
  );

  return (
    <div
      className={styles.colorItems(isSmall)}
      data-test-id={`${slugify(slug)}-color-variant-select`}
    >
      {upgradeOptions.map((upgradeOption, i) =>
        upgradeOption.fromCms.colorCode ? (
          <button
            className={styles.activeButton(
              upgradeOption === selectedUpgradeOption,
              upgradeOption.fromCms.isOutOfStock
            )}
            key={upgradeOption.name}
            style={{
              borderRadius: '50%',
              width: selectSize,
              height: selectSize,
            }}
            onClick={() => handleSelect(i)}
            type="button"
            aria-pressed={Boolean(upgradeOption === selectedUpgradeOption)}
            aria-label={`${CHOOSE_LABEL} ${productName} ${upgradeOption.displayName}`}
          >
            <div
              className={styles.colorSelectButton}
              style={{ borderRadius: '50%', width: radius, height: radius }}
            >
              <div
                className={composedClassName}
                style={{
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255,255,255, 0)',
                }}
              >
                {[upgradeOption].map(color => {
                  const borderStyles = oneColor;

                  return (
                    <span
                      key={`span-${color.name}`}
                      className={styles.innerColorSelect}
                      style={{
                        backgroundColor: `${color.fromCms.colorCode.hex}`,
                        width: radius,
                        height: radius,
                        top: position,
                        ...borderStyles,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </button>
        ) : null
      )}
    </div>
  );
};

export default memo(ColorSelectItems);
