import Hero from './Hero';
import ComparisonTable from './ComparisonTable';
import CollectionList from './CollectionList';
import ProductCard from '../ProductCard';
import SectionWrapper from '../SectionWrapper';
import IconSection from '../IconSection';

export {
  Hero,
  ComparisonTable,
  CollectionList,
  ProductCard,
  SectionWrapper,
  IconSection,
};
