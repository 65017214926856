/* eslint-disable jsx-a11y/control-has-associated-label */
import { memo, FC } from 'react';
import type { DatoProduct } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  products: DatoProduct[];
  isScroll?: boolean;
};

const HeadSection: FC<Props> = ({ products, isScroll }) => (
  <thead>
    <tr>
      <th className={styles.spacingHeader(isScroll)} scope="col" />

      {products.map(product => (
        <th key={product.id} className={styles.headingHeader} scope="col">
          {product.name}
        </th>
      ))}
    </tr>
  </thead>
);

export default memo(HeadSection);
