import { memo, FC } from 'react';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import Image from '@components/Image';
import { ROUTE_PRODUCT, ROUTE_COLLECTION } from '@constants';
import type {
  DatoCollectionComparison,
  DatoProduct,
} from '@framework/api/types';
import Link from 'next/link';
import YotpoWidget from '@components/YotpoReviews/YotpoWidget';
import IconDynamic from '@components/IconDynamic';
import useTableStyles from './useTableStyles';
import { useProductCount } from './ProductCountContext';
import ProductSectionPrice from './ProductSectionPrice';
import CollectionPrice from './CollectionPrice';

type Props = {
  products: DatoProduct[] | DatoCollectionComparison[];
  currencyCode: string;
};

const ProductSection: FC<Props> = ({ products, currencyCode }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const productCount = useProductCount();
  const { gridClass, lastItemClass } = useTableStyles(productCount);

  if (!products || products.length === 0) {
    return null;
  }

  return (
    <div className={gridClass}>
      {products.map(product => {
        const isRegionallyAvailable = Array.isArray(product?.availableRegions)
          ? product?.availableRegions.some(r => r.region === region)
          : false;

        const routePrefix =
          // eslint-disable-next-line no-underscore-dangle
          product._modelApiKey === 'product' ? ROUTE_PRODUCT : ROUTE_COLLECTION;

        return (
          <div
            key={product.id}
            className={`flex flex-col h-full pb-2 border-b nzxt-light-grey-100 ${lastItemClass}`}
          >
            <div className="w-full px-8 md:px-16 mb-3 bg-nzxt-light-grey-50 dark:bg-purpfiction">
              <Link href={`/${routePrefix}/${product.slug}`}>
                <Image
                  className="object-cover w-full mb-4 aspect-square"
                  data={
                    product.collectionPageMainImage?.responsiveImage ||
                    product.image?.responsiveImage
                  }
                  width={
                    product.collectionPageMainImage?.responsiveImage.width ||
                    product.image?.responsiveImage.width
                  }
                  height={
                    product.collectionPageMainImage?.responsiveImage.height ||
                    product.image?.responsiveImage.height
                  }
                />
              </Link>
            </div>
            <div className="flex-grow mb-3">
              <p className="mb-1 font-bold text-nzxt-light-grey-800 dark:text-white text-lg">
                {product.name}
              </p>
              <p className="mb-1 text-sm text-nzxt-light-grey-600 dark:text-nzxt-dark-grey-200">
                {product.shortDescription}
              </p>
              {product.variants?.[0]?.shopify_parent_id && (
                <YotpoWidget
                  type="star-rating"
                  id={product.variants[0].shopify_parent_id}
                  template="collection"
                />
              )}
            </div>
            <CollectionPrice
              priceBase={product.startingPrice}
              priceDiscount={product.startingDiscPrice}
              currencyCode={currencyCode}
            />
            {isRegionallyAvailable &&
              product.variants &&
              product.variants.length > 0 && (
                <ProductSectionPrice
                  currencyCode={currencyCode}
                  product={product}
                />
              )}
            {Array.isArray(product.features) && product.features.length > 0 && (
              <div className="space-y-7 border-t pt-6 pb-6">
                {product.features.map(feature => (
                  <div key={feature.id} className="flex flex-col items-center">
                    <IconDynamic
                      iconName={feature.iconName}
                      className="w-8 h-8 mb-1"
                    />
                    <p className="text-sm">{feature.heading}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default memo(ProductSection);
