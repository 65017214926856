import { classnames } from '@tailwindcss-classnames';

export const centeredItems = classnames(
  'mx-auto',
  'flex',
  'flex-col',
  'justify-center',
  'items-center'
);

export const heading = classnames(
  'border-b-2',
  'pb-4',
  'mb-4',
  'text-gradient',
  'bg-gradient-to-t',
  'to-nzxt-light-grey-500',
  'from-nzxt-light-grey-800',
  'text-4xl',
  'md:text-7xl',
  'h1',
  'text-center'
);

export const subheading = classnames(
  'pb-6',
  'md:pb-12',
  'font-semibold',
  'md:text-lg',
  'text-lg',
  'text-center',
  'text-nzxt-light-grey-800',
  'w-11/12',
  'md:max-w-4xl'
);
