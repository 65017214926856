import { classnames } from '@tailwindcss-classnames';

const smallHeading = classnames('text-sm', 'md:text-lg', 'md:leading-tight');
const defaultHeading = classnames('text-lg');

export const heading = (isSmall: boolean): string =>
  classnames(
    'font-bold',
    'text-nzxt-light-grey-800',
    'dark:text-white',
    'mb-1',
    {
      [smallHeading]: isSmall,
      [defaultHeading]: !isSmall,
    }
  );

export const container = classnames('mb-4');

export const img = classnames('max-w-lg', 'z-0');
export const optionsContainer = classnames(
  'flex',
  'items-end',
  'justify-between'
);
export const price = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200'
);
export const shortDescription = classnames(
  'flex',
  'col-span-2',
  'w-full',
  'text-xs'
);
export const defaultColor = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200'
);
