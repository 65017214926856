export default function copySearchParams(from: URL, to: URL): string {
  from.searchParams.forEach((value, key) => {
    to.searchParams.set(key, value);
  });
  return to.toString();
}

export function createUrlWithNewPathAndParams(
  baseUrl: URL,
  newPath: string
): string {
  const newUrl = new URL(baseUrl);
  newUrl.pathname = newPath;
  return newUrl.toString();
}
