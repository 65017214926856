import { classnames } from '@tailwindcss-classnames';

const activeItem = classnames('opacity-100', 'visible');
const inactiveItem = classnames('opacity-0', 'hidden');

export const container = classnames('lg:hidden');

export const wrapper = classnames('pb-1', 'mb-2');
export const textWrapper = classnames('flex', 'items-center', 'flex-wrap');
export const helperText = classnames('text-sm', 'font-semibold', 'mr-2');

export const getActiveItem = (isActive: boolean): string =>
  classnames(
    {
      [activeItem]: isActive,
      [inactiveItem]: !isActive,
    },
    'transition-all',
    'duration-100'
  );

export const mediaWrapper = classnames('relative');

const inActiveBackground = classnames(
  'border',
  'border-nzxt-light-grey-600',
  'border-dashed'
);

const inActiveBackgroundAvail = classnames(
  'border-nzxt-light-grey-600',
  'border'
);
const activeBackground = classnames('border-2', 'border-nzxt-volt-300');

export const activeButton = (isActive: boolean, isAvailable: boolean): string =>
  classnames(
    {
      [inActiveBackground]: !isActive && isAvailable,
      [inActiveBackgroundAvail]: !isActive && !isAvailable,
      [activeBackground]: isActive,
    },
    'flex',
    'items-center',
    'justify-center',
    'mr-2'
  );

export const colorSelectButton = classnames(
  'border-2',
  'border-transparent',
  'flex',
  'flex-row',
  'items-center',
  'justify-center',
  'focus:p-4',
  'relative'
);

const small = classnames('hidden', 'md:flex');

export const innerColorSelect = classnames('absolute');

export const colorItems = (isSmall: boolean): string =>
  classnames('flex', 'items-start', { [small]: isSmall });

export const productColor = classnames(
  'text-sm',
  'text-nzxt-light-grey-700',
  'dark:text-nzxt-dark-grey-200'
);

export const galleryWrapper = classnames(
  'h-full',
  'lg:col-span-6',
  'xl:col-span-7',
  'col-span-1',
  'row-span-full',
  'order-1'
);

export const imageItem = classnames(
  'col-span-1',
  'flex',
  'justify-center',
  'bg-nzxt-light-grey-50',
  'dark:bg-transparent',
  'p-4',
  'xl:p-10',
  'first:col-span-2',
  'cursor-pointer'
);

export const modalSliderWrapper = classnames('mb-3');

// mobile
export const productDisplay = classnames(
  'relative',
  'flex',
  'px-0',
  'pb-0',
  'box-border',
  'col-span-1',
  'order-1'
);

export const img = classnames('w-full', 'h-auto', 'max-h-full', 'object-cover');
