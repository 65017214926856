import { memo, FC, useMemo } from 'react';
import type { DatoProduct } from '@framework/api/types';
import ProductCta from './ProductCta';
import DataSection from './DataSection';
import ProductSection from './ProductSection';

type Props = {
  products: DatoProduct[];
  specData: Array<{ label: string; value: Array<string> }>;
  currencyCode: string;
};

const LargeTable: FC<Props> = ({ products, currencyCode, specData }) => {
  const productData = useMemo(
    () =>
      products.map(p => ({
        slug: p.slug,
        // eslint-disable-next-line no-underscore-dangle
        modelApiKey: p._modelApiKey,
      })),
    [products]
  );

  return (
    <>
      <ProductSection products={products} currencyCode={currencyCode} />
      <DataSection specs={specData} />
      <ProductCta productData={productData} />
    </>
  );
};

export default memo(LargeTable);
