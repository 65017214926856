import { memo, FC } from 'react';
import cn from 'clsx';
import usePrice from '@framework/use-price';
import type { DatoProduct } from '@framework/api/types';
import * as styles from './styles';

type Props = {
  currencyCode: string;
  product: DatoProduct;
  optionalClass?: string;
};

// TODO: Remove 'suppressHydrationWarning' from price items
// suppressHydrationWarning is a temporary solution for the server/client mismatch

const ProductSectionPrice: FC<Props> = ({
  currencyCode,
  product,
  optionalClass,
}) => {
  const { collectionPagePrice, collectionPageDiscPrice } = product;

  const { price } = usePrice({
    amount: collectionPagePrice,
    currencyCode,
  });

  const { price: discoPrice } = usePrice({
    amount: collectionPageDiscPrice,
    currencyCode,
  });

  return (
    <div className={styles.priceContainer}>
      <p
        suppressHydrationWarning
        className={cn(
          optionalClass,
          styles.isOnSale(
            collectionPageDiscPrice &&
              collectionPageDiscPrice > 0 &&
              collectionPageDiscPrice !== collectionPagePrice
          )
        )}
      >
        {price}
      </p>
      {collectionPageDiscPrice &&
        collectionPageDiscPrice > 0 &&
        collectionPageDiscPrice !== collectionPagePrice && (
          <p
            suppressHydrationWarning
            className={cn(optionalClass, styles.productPrice)}
          >
            {discoPrice}
          </p>
        )}
    </div>
  );
};

export default memo(ProductSectionPrice);
