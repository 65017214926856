/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useMemo, useState, useCallback, FC } from 'react';
import type { DatoProduct } from '@framework/api/types';
import SectionHeading from '@components/SectionHeading';
import ContentWrapper from '@components/ContentWrapper';
import ProductSelector from './ProductSelector';
import { ProductCountProvider } from './ProductCountContext';

import LargeTable from './LargeTable';

type Props = {
  heading: string;
  products: DatoProduct[];
  currencyCode: string;
};

const Table: FC<Props> = ({ products, currencyCode, heading }) => {
  const initialProductIds = products.map(product => product.id);

  const [selectedProductIds, setSelectedProductIds] =
    useState<string[]>(initialProductIds);

  const handleProductSelect = useCallback(
    (index: number, productId: string): void => {
      let newSelectedIds = [...selectedProductIds];

      newSelectedIds[index] = productId;

      const findNextUniqueProductId = (
        excludeIds: string[]
      ): string | undefined =>
        products.find(p => !excludeIds.includes(p.id))?.id;

      const uniqueProductIds = new Set(newSelectedIds);
      newSelectedIds = newSelectedIds.map((id, idx) => {
        if (idx !== index && id === productId) {
          const nextUniqueProductId = findNextUniqueProductId([
            ...uniqueProductIds,
          ]);
          if (nextUniqueProductId) {
            uniqueProductIds.add(nextUniqueProductId);
            return nextUniqueProductId;
          }
        }
        return id;
      });

      setSelectedProductIds(newSelectedIds);
    },
    [selectedProductIds, products]
  );

  const filteredProducts = useMemo(
    () =>
      selectedProductIds
        .map(productId => products.find(product => product.id === productId))
        .filter(product => product !== undefined),
    [products, selectedProductIds]
  );

  const displayProducts = useMemo(
    () => filteredProducts.slice(0, 3),
    [filteredProducts]
  );

  const output = useMemo(() => {
    const specsAggregate = new Map();

    displayProducts.forEach(product => {
      if (product.specs) {
        Object.entries(product.specs).forEach(([label, value]) => {
          if (!specsAggregate.has(label)) {
            specsAggregate.set(label, []);
          }
          specsAggregate.get(label).push(value);
        });
      }
    });

    return Array.from(specsAggregate).map(([label, values]) => ({
      label,
      value: values,
    }));
  }, [displayProducts]);

  return (
    <>
      {products && output && (
        <>
          <SectionHeading heading={heading || 'Compare Models'} />
          <ContentWrapper>
            <ProductCountProvider products={products}>
              <div
                className={`${
                  initialProductIds.length === 2
                    ? 'max-w-4xl m-auto'
                    : 'max-w-full'
                }`}
              >
                <ProductSelector
                  products={filteredProducts}
                  onSelect={handleProductSelect}
                  selectedIds={selectedProductIds}
                />
                <LargeTable
                  products={displayProducts}
                  specData={output}
                  currencyCode={currencyCode}
                />
              </div>
            </ProductCountProvider>
          </ContentWrapper>
        </>
      )}
    </>
  );
};

export default memo(Table);
