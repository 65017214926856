import { useMemo } from 'react';

type Props = {
  gridClass: string;
  lastItemClass: string;
};

const useTableStyles = (productCount: number): Props => {
  const gridClass = useMemo(
    () =>
      productCount >= 3
        ? 'grid items-start grid-cols-2 gap-6 md:grid-cols-3 lg:gap-6 xl:gap-8'
        : 'grid items-start grid-cols-2 gap-6 md:grid-cols-2 lg:gap-6 xl:gap-8',
    [productCount]
  );

  const lastItemClass = useMemo(
    () => (productCount === 2 ? '' : 'last:hidden md:last:inline-grid'),
    [productCount]
  );

  return { gridClass, lastItemClass };
};

export default useTableStyles;
