import { memo, FC } from 'react';
import ButtonLink from '@components/ButtonLink';
import { ROUTE_PRODUCT, ROUTE_COLLECTION } from '@constants';
import { useTranslation as t } from '@utils/hooks';
import useTableStyles from './useTableStyles';
import { useProductCount } from './ProductCountContext';

type ProductData = {
  slug: string;
  modelApiKey: string;
};

type Props = {
  productData: ProductData[];
};

const ProductCta: FC<Props> = ({ productData }) => {
  const productCount = useProductCount();
  const { gridClass, lastItemClass } = useTableStyles(productCount);

  if (!productData || productData.length === 0) {
    return null;
  }

  const SHOP_LABEL = t('label_shop');

  return (
    <div className={gridClass}>
      {productData.map(({ slug, modelApiKey }) => {
        if (!slug) {
          return null;
        }

        const routePrefix =
          modelApiKey === 'product' ? ROUTE_PRODUCT : ROUTE_COLLECTION;

        return (
          <div key={slug} className={`text-center mt-4 ${lastItemClass}`}>
            <ButtonLink
              asButton
              buttonStyle="primary"
              href={`/${routePrefix}/${slug}`}
              internalLink
              customMargin
            >
              {SHOP_LABEL}
            </ButtonLink>
          </div>
        );
      })}
    </div>
  );
};

export default memo(ProductCta);
