/* eslint-disable react/jsx-no-useless-fragment */
import { memo, FC, Fragment, ReactNode } from 'react';
import { IconCheck, IconX } from '@nzxt/react-icons';

import * as styles from './styles';

type Props = {
  label: string;
  value: string[] | string;
};

const getVal = (feat): ReactNode => {
  switch (feat) {
    case feat.length === 0:
      return <IconX className={styles.svgXStyles} />;

    case feat === false || 'No' || 'no' || 'Nein' || 'nein':
      return <IconX className={styles.svgXStyles} />;

    case feat === true || 'Yes' || 'yes' || 'Ja' || 'ja' || ' Ja':
      return <IconCheck className={styles.svgCheckStyles} />;

    case feat.length > 0:
      return feat;

    case feat:
      return feat;

    default:
      return <IconX className={styles.svgXStyles} />;
  }
};

const CellItem: FC<Props> = ({ label, value }) => (
  <>
    {Array.isArray(value) ? (
      value.map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <td key={`${label}-${index}`} className={styles.tdSpacing}>
          {Array.isArray(val) ? (
            <>
              {val.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${item}-${i}`}>{getVal(item)}</Fragment>
              ))}
            </>
          ) : (
            <>{getVal(val)}</>
          )}

          <span className={styles.srOnly}>
            {label}: {val}
          </span>
        </td>
      ))
    ) : (
      <td className={styles.tdSpacing}>{getVal(value)}</td>
    )}
  </>
);

export default memo(CellItem);
