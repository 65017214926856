import { createContext, useContext, FC, ReactNode } from 'react';
import type { DatoProduct } from '@framework/api/types';

const ProductCountContext = createContext<number>(0);

type Props = {
  products: DatoProduct[];
  children: ReactNode;
};

export const ProductCountProvider: FC<Props> = ({ children, products }) => (
  <ProductCountContext.Provider value={products.length}>
    {children}
  </ProductCountContext.Provider>
);

export const useProductCount = (): number => useContext(ProductCountContext);
