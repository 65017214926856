import { classnames } from '@tailwindcss-classnames';

// sr-only

export const srOnly = classnames('sr-only');

// cell item
export const tdSpacing = classnames(
  'py-5',
  'pr-4',
  'lg:py-5',
  'lg:px-6',
  'text-nzxt-light-grey-800',
  'text-center'
);

export const svgStyles = classnames('h-5', 'w-5', 'mx-auto');
export const svgCheckStyles = classnames(svgStyles, 'text-nzxt-green-400');
export const svgXStyles = classnames(svgStyles, 'text-nzxt-red-400');

// data section
const scrollDataTableHeadings = classnames(
  'sticky',
  'left-0',
  'bg-nzxt-light-grey-50'
);

const scrollDataTableHeadingsFaux = classnames('sticky', 'left-0');

export const dataTableHeadings = (isScroll: boolean): string =>
  classnames(
    'py-5',
    'px-6',
    'text-sm',
    'font-normal',
    'text-left',
    'text-nzxt-light-grey-600',
    {
      [scrollDataTableHeadings]: isScroll,
    }
  );

export const dataTableHeadingsFaux = (isScroll: boolean): string =>
  classnames(
    'py-5',
    'px-6',
    'text-sm',
    'font-normal',
    'text-left',
    'text-nzxt-light-grey-600',
    'invisible',
    {
      [scrollDataTableHeadingsFaux]: isScroll,
    }
  );

// product section
export const scrollProductSectionImage = classnames('w-48', 'md:w-80', 'mb-4');

const scrollProductTableHeading = classnames(
  scrollDataTableHeadings,
  'block',
  'w-32',
  'md:w-56',
  'h-full',
  'z-10',
  'flex',
  'justify-end',
  'items-end'
);
export const productTableHeading = (isScroll: boolean): string =>
  classnames('py-8', 'px-6', 'text-sm', 'font-medium', 'text-right', {
    [scrollProductTableHeading]: isScroll,
  });

export const productTableData = classnames(
  'py-8',
  'px-6',
  'h-full',
  'align-top'
);

export const productWrapper = classnames('relative', 'table', 'h-full');

export const productSubheading = classnames(
  'text-sm',
  'text-nzxt-light-grey-600'
);

export const priceContainer = classnames('flex', 'flex-wrap', 'w-full');
export const productPrice = classnames('mb-2');
export const price = classnames('opacity-100');
const priceDiscounted = classnames(
  'line-through',
  'text-nzxt-light-grey-600',
  'text-center',
  'opacity-75',
  'mr-2'
);
export const isOnSale = (salePrice: boolean): string =>
  classnames({
    [productPrice]: true,
    [priceDiscounted]: salePrice,
    [price]: !salePrice,
  });

export const productButtons = classnames(
  'text-sm',
  'font-semibold',
  'text-white',
  'text-center',
  'hover:bg-gray-900',
  'py-2',
  'rounded-md',
  'bg-gray-800',
  'w-full',
  'block',
  'flex-grow',
  'bottom-0',
  'absolute'
);

// head section
const scrollspacingHeader = classnames(
  'sticky',
  'left-0',
  'bg-nzxt-light-grey-50'
);

export const spacingHeader = (isScroll: boolean): string =>
  classnames('pb-4', 'px-6', { [scrollspacingHeader]: isScroll });
export const headingHeader = classnames(
  'mb-2',
  'mt-2',
  'text-center',
  'text-2xl',
  'md:text-3xl',
  'font-bold',
  'leading-relaxed',
  'text-nzxt-light-grey-800'
);

export const headingSubtype = classnames('font-thin');

// table
export const tableBody = classnames('divide-y', 'divide-gray-200');

export const largeTable = classnames(
  'w-full',
  'h-px',
  'table-fixed',
  'hidden',
  'lg:table'
);

// single item
export const specsSection = classnames(
  'grid',
  'grid-cols-12',
  'gap-2',
  'md:gap-2',
  'text-nzxt-light-grey-800',
  'border-t',
  'pt-2'
);

export const specsWrapper = classnames(
  'pt-4',
  'pb-6',
  'md:grid',
  'md:grid-cols-4',
  'block',
  'justify-between',
  'md:col-span-6',
  'col-span-12',
  'border-b'
);

// inner
export const specValueDetail = classnames('col-span-2');
export const specValueLink = classnames(
  'text-nzxt-blue-400',
  'hover:underline',
  'flex',
  'gap-2',
  'items-center'
);
export const specCategory = classnames(
  'font-semibold',
  'text-nzxt-light-grey-600',
  'col-span-2'
);

export const singleItemWrapper = classnames(
  'flex',
  'flex-col',
  'justify-center',
  'items-center',
  'text-center'
);
export const singleItemImgWrapper = classnames('max-w-xs', 'mx-auto');
export const singleItemProductSubheading = classnames(
  'text-sm',
  'text-nzxt-light-grey-600',
  'text-center'
);
export const priceStyles = classnames(
  'my-2',
  'text-lg',
  'md:text-2xl',
  'font-extrabold'
);

// scroll table
export const scrollTableWrapper = classnames(
  'w-full',
  'h-px',
  'table',
  'whitespace-nowrap'
);

export const scrollContainer = classnames(
  'ml-4',
  'md:ml-28',
  'overflow-scroll',
  'relative'
);

export const scrollIconContainer = classnames(
  'flex',
  'items-center',
  'mr-auto',
  'text-nzxt-light-grey-800'
);
