import { memo, FC } from 'react';
import type {
  DatoCollectionComparison,
  DatoProduct,
} from '@framework/api/types';
import { Table } from './Table';

type Props = {
  heading?: string;
  products?: DatoProduct[] | DatoCollectionComparison[];
  currencyCode: string;
};
const ComparisonTable: FC<Props> = ({ heading, products, currencyCode }) => (
  <Table heading={heading} products={products} currencyCode={currencyCode} />
);
export default memo(ComparisonTable);
